import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import PageHeader from "../../components/PageHeader/PageHeader"
import SocialShare from "../../components/SocialShare/SocialShare"
import "./BlogPostPage.css"

export const query = graphql`
    query($slug: String!) {
        allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    slug
                    seoTitle
                    seoDescription
                    title
                    category {
                        title
                    }
                    content {
                        childMarkdownRemark {
                            html
                        }
                    }
                    datePublished(formatString: "DD. MMMM YYYY.")
                }
            }
        }
    }
`

const BlogPostTemplate = ({ location, data: { allContentfulBlogPost } }) => {
    const {
        seoTitle,
        seoDescription,
        title,
        category,
        content,
        datePublished,
    } = allContentfulBlogPost.edges[0].node

    return (
        <Layout location={location}>
            <SEO title={seoTitle} description={seoDescription} />
            <div className="Blog BlogPostPage">
                <div className="Blog-container">
                    <PageHeader
                        PageHeaderTitle={"Back to Blog"}
                        PageHeaderUrl="/blog"
                    />
                    <article className="blog-post">
                        {title && <h1 className="blog-post__title">{title}</h1>}
                        <p className="blog-post__info">
                            {datePublished && (
                                <React.Fragment>
                                    on
                                    <strong>{datePublished}</strong>
                                </React.Fragment>
                            )}
                            {category && (
                                <React.Fragment>
                                    in category
                                    <strong>{category[0].title}</strong>
                                </React.Fragment>
                            )}
                        </p>
                        {content && (
                            <div
                                className="blog-post__text"
                                dangerouslySetInnerHTML={{
                                    __html: content.childMarkdownRemark.html,
                                }}
                            />
                        )}
                    </article>
                    <span className="blog-post__message">
                        Thank You for Reading
                    </span>
                    <SocialShare
                        location={location}
                        classes="SocialShare--centered"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default BlogPostTemplate
